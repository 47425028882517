<template>
  <div class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Menu - Zápatí - Kategorie - Upravit <span class="text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item
          label="Nadpis"
          :validate-status="nameError() ? 'error' : ''"
          :help="nameError() || ''"
        >
          <a-input @change="handleChange" placeholder="Název" v-decorator="['mfe_title', {rules: [{max: 30, message: 'Název nemůže být delší než 30 znaků'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>
        <a-form-item>
          <span slot="label">
            Blog&nbsp;
            <a-tooltip title="Pod nadpisem se zobrazí seznam blog kategorií">
              <a-icon type="question-circle-o"/>
            </a-tooltip>
          </span>
          <a-checkbox :disabled="this.form.getFieldValue('mfe_is_ecommerce_categories')" v-decorator="['mfe_is_blog', { valuePropName: 'checked', initialValue: false }]"/>
        </a-form-item>

        <a-form-item>
          <span slot="label">
            E-shop&nbsp;
            <a-tooltip title="Pod nadpisem se zobrazí seznam e-shop kategorií">
              <a-icon type="question-circle-o"/>
            </a-tooltip>
          </span>
          <a-checkbox :disabled="this.form.getFieldValue('mfe_is_blog')" v-decorator="['mfe_is_ecommerce_categories', { valuePropName: 'checked', initialValue: false }]"/>
        </a-form-item>
        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '@/services/forms'

export default {
  components: { ActionTools, LanguageTab },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      onFieldsChange: (props, fields) => {
        const key = Object.keys(fields)[0]
        if (this.checkboxes.includes(key)) {
          this.changeCheckbox(fields, key)
        }
      },
    })
  },
  data: function () {
    return {
      hasErrors,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/menu/footer/category',
          title: 'Seznam',
        },
      ],
      item: {
        mfe_is_blog: null,
        mfe_is_ecommerce_categories: null,
        languages: [],
      },
      checkboxes: ['mfe_is_ecommerce_categories', 'mfe_is_blog'],
      id: this.$route.params.id,
      loaded: false,
    }
  },
  computed: {
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    refreshComponent(id) {
      this.id = id
      this.item = {
        mfe_is_blog: null,
        mfe_is_ecommerce_categories: null,
        languages: [],
      }
      this.loaded = false
    },
    changeCheckbox(fieldData, fieldName) {
      if (this.form.getFieldValue(fieldName)) {
        this.form.setFieldsValue({
          [this.checkboxes.find(x => x !== fieldName)]: false,
        })
      }
    },
    getData() {
      return {
        mfe_is_blog: this.form.getFieldValue('mfe_is_blog'),
        mfe_is_ecommerce_categories: this.form.getFieldValue('mfe_is_ecommerce_categories'),
        languages: this.item.languages.filter(x => x.mfe_title.trim().length > 0),
      }
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('menuFooterTitle/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    handleChange(e) {
      let found = false
      this.item.languages.some((value, key) => {
        if (value.lge_id === this.language) {
          this.item.languages[key] = { lge_id: this.language, mfe_title: e !== undefined ? e.target.value : '' }
          found = true
          return true
        }
      })
      if (!found) {
        this.item.languages.push(
          { lge_id: this.language, mfe_title: e !== undefined ? e.target.value : '' },
        )
      }
    },
    handleInit() {
      this.$store.dispatch('menuFooterTitle/getOne', this.id)
        .then(() => {
          this.item.languages.push(this.$store.getters['menuFooterTitle/currentLanguageDetail'])
          this.form.setFieldsValue({
            mfe_title: this.item.languages.find(x => x.lge_id === this.language).mfe_title,
          })
          if (!this.loaded) {
            this.item.mfe_is_blog = this.$store.getters['menuFooterTitle/getDetail'].mfe_is_blog
            this.item.mfe_is_ecommerce_categories = this.$store.getters['menuFooterTitle/getDetail'].mfe_is_ecommerce_categories
            this.form.setFieldsValue({
              mfe_is_blog: this.item.mfe_is_blog,
              mfe_is_ecommerce_categories: this.item.mfe_is_ecommerce_categories,
            })
            this.loaded = true
          }
          this.form.validateFields()
        }).catch(() => {})
    },
    nameError() {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched('mfe_title') && getFieldError('mfe_title')
    },
  },
  created() {
    if (this.language !== null && this.item.languages.filter(x => x.lge_id === this.language).length === 0) {
      this.handleInit()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (newValue !== null) {
        if (this.item.languages.filter(x => x.lge_id === newValue).length === 0) {
          this.handleInit()
        } else {
          this.form.setFieldsValue({
            mfe_title: this.item.languages.find(x => x.lge_id === newValue).mfe_title,
          })
          this.form.validateFields()
        }
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.handleInit()
      }
    },
  },
}
</script>
